"use client";

import { useEffect, useState } from "react";
import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import { fetchMedia } from "@local/payload-client/src/media";
import { ArrowLeftIcon } from "../ui/icons";
import { barlowCondensed } from "@/utils/fonts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from "swiper/modules";
import Link from "next/link";
import { isBrowser } from "@local/utils";
import styles from "./styles.module.css";
import "swiper/css";
interface Props {
  block: Payload.CategoriesListingBlock;
}
export const CategoriesListingCarouselBlock = ({
  block
}: Props) => {
  const [images, setImages] = useState<(Payload.Media | null)[]>([]);
  const [altImages, setAltImages] = useState<(Payload.Media | null)[]>([]);
  const [controlledSwiper, setControlledSwiper] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(isBrowser() ? window.innerWidth : 0);
  useEffect(() => {
    if (isBrowser()) {
      const handleResize = () => setViewportWidth(window ? window.innerWidth : 1024);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  useEffect(() => {
    const fetchImages = async () => {
      const images = block.categories ? await Promise.all(block.categories.map(async (category: any) => {
        const image = await fetchMedia({
          id: category.image
        });
        return image;
      })) : [];
      const altImages = block.categories ? await Promise.all(block.categories.map(async (category: any) => {
        const altImage = await fetchMedia({
          id: category.altImage
        });
        return altImage;
      })) : [];
      setImages(images);
      setAltImages(altImages);
    };
    fetchImages();
  }, [block.categories]);
  const headline = block.headline ?? "Shop by Category";
  const totalSlides = block.categories?.length ?? 0;
  const slidesPerView = viewportWidth < 640 ? 1.7 : viewportWidth > 792 ? 4 : 2;
  const totalWidth = viewportWidth > 840 ? 792 : 342;
  const paginationWidth = totalWidth / (totalSlides - slidesPerView + 1);
  const percentage = currentIndex / (totalSlides - slidesPerView + 1) * 100;
  const SingleCategoryCard = ({
    index,
    category
  }: {
    index: number;
    category: any;
  }) => {
    const [backgroundImage, setBackgroundImage] = useState<string>(`url(${images[index]?.url || "./images/marble_bg.png"})`);
    const [mouseOver, setMouseOver] = useState(false);
    const handleHover = (mouseEnter: boolean) => {
      if (mouseEnter) {
        setBackgroundImage(`url(${altImages[index]?.url} || "./notfound.svg")`);
        setMouseOver(true);
        return;
      }
      setBackgroundImage(`url(${images[index]?.url} ?? url(${altImages[index]?.url} ?? "./notfound.svg")`);
      setMouseOver(false);
    };
    return <div className="relative" onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} data-sentry-component="SingleCategoryCard" data-sentry-source-file="index.tsx">
        <div className="w-full aspect-[3/4] overflow-hidden">
          <div className="w-full h-full" style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "top center",
          transition: "all .3s ease-in-out",
          transform: mouseOver ? "scale(1.05)" : "scale(1)"
        }} />
        </div>
        {category?.imageTag && category.imageTag.length > 0 ? <div className="bg-[#FCC810] absolute top-3 right-3 px-3 py-1 rounded-md uppercase font-bold">
            {category.imageTag}
          </div> : null}
        {/* <Image
              alt={`Image for ${category?.name} category`}
              className="object-fill object-center group-hover:opacity-75"
              height={images[i]?.height}
              width={images[i]?.width}
              src={images[i]?.url || "./notfound.svg"}
              loader={
                process.env.NEXT_PUBLIC_BUNNY_URL
                  ? BunnyLoader
                  : undefined
              }
            /> */}
        <h3 className="mt-1 text-2xl font-semibold uppercase">
          <Link href={`/category/${category?.slug}`} prefetch={false} data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <span className="absolute inset-0" />
            {category?.name}
          </Link>
        </h3>
        <h4 className="text-lg uppercase">
          Explore Category <span className="text-[#C52D0B]">{">"}</span>
        </h4>
      </div>;
  };
  return <ContentBlockServer className="flex flex-col justify-center py-10 mt-8 mb-4 lg:py-6 !max-w-6xl" data-sentry-element="ContentBlockServer" data-sentry-component="CategoriesListingCarouselBlock" data-sentry-source-file="index.tsx">
      <div className={`${barlowCondensed.className} mx-6 xl:mx-0`}>
        <h2 className="flex md:relative text-5xl uppercase tracking-[-0.02em]">
          <ArrowLeftIcon className="hidden xl:block mr-2 md:mr-0 md:absolute w-9 rotate-180 -left-12 top-[7px] text-[#E5E5E5]" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="index.tsx" />
          {headline.split(" ").slice(0, -1).join(" ")}
          <span className="ml-2 font-semibold">
            {headline.split(" ").slice(-1)}
          </span>
        </h2>

        <div className="relative py-8">
          <Swiper slidesPerView={slidesPerView} spaceBetween={slidesPerView > 2 ? 30 : 15} modules={[Controller]} onSwiper={setControlledSwiper as any} onSlideChange={() => setCurrentIndex(controlledSwiper?.activeIndex || 0)} className={viewportWidth < 640 ? styles.swiperContainer : ""} slidesOffsetAfter={viewportWidth < 640 ? 32 : 0} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">
            {block.categories?.map((category: any, i) => {
            return <SwiperSlide key={`category-${(category as Payload.Category).id}`}>
                  <SingleCategoryCard index={i} category={category} />
                </SwiperSlide>;
          })}
          </Swiper>
          <div className="hidden absolute z-10 sm:flex items-center justify-center w-4 h-4 p-8 transition-transform duration-200 transform bg-white rounded-full shadow-xl cursor-pointer top-1/3 -left-4 xl:-left-10 hover:scale-110 active:scale-90" onClick={() => {
          if (currentIndex > 0) controlledSwiper?.slideTo(currentIndex - 1);
        }}>
            <div>
              <ArrowLeftIcon className="w-5 h-5" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="index.tsx" />
            </div>
          </div>
          <div className="hidden absolute z-10 sm:flex items-center justify-center w-4 h-4 p-8 transition-transform duration-200 transform rotate-180 bg-white rounded-full shadow-xl cursor-pointer top-1/3 -right-4 xl:-right-10 hover:scale-110 active:scale-90" onClick={() => {
          if (currentIndex < totalSlides - slidesPerView + 1) {
            controlledSwiper?.slideTo(currentIndex + 1);
          }
        }}>
            <div>
              <ArrowLeftIcon className="w-5 h-5" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="index.tsx" />
            </div>
          </div>
          <div className={`relative mt-8 mx-auto`} style={{
          maxWidth: `${totalWidth}px`
        }}>
            <div className="h-2 bg-[#F7F7F7] rounded-full" />
            <div className={`z-10 absolute top-0 h-2 bg-black rounded-full`} style={{
            transition: "left 0.3s",
            left: `${percentage}%`,
            width: `${paginationWidth}px`
          }} />
          </div>
        </div>
      </div>
      <ButtonServer content={block.ctaText ?? "View All Categories"} intent={ButtonIntent.DarkCTA} stretch={ButtonStretch.flexible} url="/categories" className="mx-auto !py-6 uppercase" data-sentry-element="ButtonServer" data-sentry-source-file="index.tsx" />
    </ContentBlockServer>;
};
export default CategoriesListingCarouselBlock;